import styled from 'styled-components';

import { cssBreakpoints } from 'theme/theme';

export const CmsSplitContentBlockFullWidthVideoBackgroundHolder = styled.div`
  display: contents;

  @media all and (max-width: ${cssBreakpoints.smDown}) {
    display: none;
  }

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    & + * {
      pointer-events: none;
    }
  }
`;

export const CmsSplitContentBlockFullWidthVideoHolder = styled.div`
  display: contents;

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    display: none;
  }
`;

export const CmsSplitContentBlockFullWidthVideoPlaceholder = styled.div`
  width: 50%;

  @media all and (max-width: ${cssBreakpoints.smDown}) {
    display: none;
  }
`;
