import loadable from '@loadable/component';
import { ElementType, Fragment, useEffect, useState } from 'react';

import { Box } from 'components/Box';
import { FilestackImage } from 'components/FilestackImage/FilestackImage';
import {
  CmsApiSplitBlockImage,
  CmsApiSplitContentBlockData,
} from 'modules/cms/api/types/blocks/CmsApiSplitContentBlock';
import { breakpointValues, gutterWidth } from 'theme/theme';

import {
  CmsSplitContentBlockFullWidthVideoBackgroundHolder,
  CmsSplitContentBlockFullWidthVideoHolder,
  CmsSplitContentBlockFullWidthVideoPlaceholder,
} from './CmsSplitContentBlock.styled';

const blockContentMap: { [key: string]: ElementType } = {
  IMAGE: loadable(() => import('./CmsSplitContentImageContent'), {
    resolveComponent: (module) => module.CmsSplitContentImageContent,
  }),
  TEXT: loadable(() => import('./CmsSplitContentTextContent'), {
    resolveComponent: (module) => module.CmsSplitContentTextContent,
  }),
  VIDEO: loadable(() => import('./Video/CmsSplitContentVideoContent'), {
    resolveComponent: (module) => module.CmsSplitContentVideoContent,
  }),
  BLOCKQUOTE: loadable(() => import('./CmsSplitContentBlockquoteContent'), {
    resolveComponent: (module) => module.CmsSplitContentBlockquoteContent,
  }),
};

const VideoContent = blockContentMap.VIDEO;

type Props = {
  blockId: string;
  data: CmsApiSplitContentBlockData;
  blockIndex: number;
  pageUpdated: string;
  preview?: boolean;
};

export function CmsSplitContentBlock({
  blockId,
  data: { sections, appearance },
  blockIndex,
  pageUpdated,
  preview,
}: Props) {
  const [isDesktop, setIsDesktop] = useState(true);
  useEffect(() => {
    const resizeHandler = () =>
      setIsDesktop(window && window.innerWidth >= breakpointValues.sm);

    window.addEventListener('resize', resizeHandler);
    resizeHandler();
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);
  const sectionsKey = `${sections[0].type}_${sections[1].type}`;
  const imageTextBlock = ['IMAGE_TEXT', 'TEXT_IMAGE'].includes(sectionsKey);
  const imageQuoteBlock = ['IMAGE_BLOCKQUOTE', 'BLOCKQUOTE_IMAGE'].includes(
    sectionsKey,
  );
  const imageSection = sections.find(
    (section) => section.type === 'IMAGE',
  ) as CmsApiSplitBlockImage;
  const showBackgroundImage =
    imageSection && imageSection.data.isMobileBackground && !isDesktop;

  const fullWidthVideoSection =
    appearance === 'FULL_WIDTH' &&
    sections.find((section) => section.type === 'VIDEO');

  const flipOnMobile =
    ['TEXT', 'BLOCKQUOTE'].includes(sections[0].type) &&
    sections[1].type === 'IMAGE';

  return (
    <div
      style={{
        marginLeft: showBackgroundImage ? -24 : undefined,
        marginRight: showBackgroundImage ? -24 : undefined,
      }}
      data-qa-id={`split-content-block-${blockIndex}`}
    >
      {showBackgroundImage && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <FilestackImage
            data={imageSection.data.image}
            width={1920}
            expandSize
            objectFit="cover"
            eagerLoad={blockIndex < 2}
            mobileAspectRatio={{ width: 414, height: 470 }}
          />
        </div>
      )}

      {fullWidthVideoSection && (
        <CmsSplitContentBlockFullWidthVideoBackgroundHolder>
          <VideoContent
            section={fullWidthVideoSection}
            appearance={appearance}
            imageTextBlock={imageTextBlock}
            showBackgroundImage={showBackgroundImage}
            index={sections.indexOf(fullWidthVideoSection)}
            blockId={blockId}
            blockIndex={blockIndex}
            pageUpdated={pageUpdated}
            preview={preview}
            desktopOnly
          />
        </CmsSplitContentBlockFullWidthVideoBackgroundHolder>
      )}

      <Box
        // @ts-expect-error TS(2322): Type 'false | 48' is not assignable to type 'Respo... Remove this comment to see the full error message
        py={appearance !== 'SIDE_BY_SIDE' && gutterWidth}
      >
        <Box
          position="relative"
          display="flex"
          borderRadius={
            (imageTextBlock || imageQuoteBlock) && !showBackgroundImage
              ? ['12px', null, 0]
              : undefined
          }
          boxShadow={
            imageTextBlock && !showBackgroundImage
              ? ['0 4px 9px rgba(0, 0, 0, 0.1)', null, 'none']
              : undefined
          }
          flexDirection={[
            flipOnMobile ? 'column-reverse' : 'column',
            null,
            'row',
          ]}
          // @ts-expect-error TS(2322): Type 'false | "center"' is not assignable to type ... Remove this comment to see the full error message
          alignItems={appearance === 'FULL_WIDTH' && 'center'}
          m={[imageTextBlock ? 0 : -gutterWidth / 4, null, -gutterWidth / 2]}
          width={[
            imageTextBlock ? '100%' : `calc(100% + ${gutterWidth / 2}px)`,
            null,
            `calc(100% + ${gutterWidth}px)`,
          ]}
          minHeight={[
            'initial',
            null,
            appearance === 'FULL_WIDTH' ? 'calc(100vh - 300px)' : null,
          ]}
        >
          {sections.map((section, index) => {
            const Content = blockContentMap[section.type];

            const contentNode = (
              <Content
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                section={section}
                appearance={appearance}
                imageTextBlock={imageTextBlock}
                imageQuoteBlock={imageQuoteBlock}
                showBackgroundImage={showBackgroundImage}
                index={index}
                blockId={blockId}
                blockIndex={blockIndex}
                pageUpdated={pageUpdated}
                preview={preview}
              />
            );

            // TODO: refactor the way we wrap the blocks so that we don't have to duplicate content
            if (section.type === 'VIDEO' && appearance === 'FULL_WIDTH') {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={index}>
                  <CmsSplitContentBlockFullWidthVideoPlaceholder />

                  <CmsSplitContentBlockFullWidthVideoHolder>
                    {contentNode}
                  </CmsSplitContentBlockFullWidthVideoHolder>
                </Fragment>
              );
            }

            return contentNode;
          })}
        </Box>
      </Box>
    </div>
  );
}
